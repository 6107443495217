<template>
  <div>
    <page-header>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never" class="content-card-panel" v-loading="loading">
        <el-row>
          <el-col>
            <el-form :model="editForm" :rules="rules" ref="editForm" label-width="120px">
              <el-form-item label="视频标题" prop="title">
                <el-input v-model="editForm.title"></el-input>
              </el-form-item>
              <el-form-item label="发布时间" prop="publishTime">
                <el-date-picker
                    v-model="editForm.publishTime"
                    type="datetime"
                    :picker-options="pickerOptions"
                    default-time="07:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="发布时间">
                </el-date-picker>
                <el-popover
                    style="margin-left: 20px"
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    content="将素材添加到推送列表，需要配合发布时间使用，系统默认每天08:00给用户推送消息，需要将发布时间设置为08:00以前">
                  <el-checkbox v-model="editForm.isPush" slot="reference">推送&nbsp;<i
                      class="el-icon-warning-outline"></i>
                  </el-checkbox>
                </el-popover>
              </el-form-item>
              <el-form-item label="分类" prop="categories">
                <category-select v-model="editForm.categories" width="220px"></category-select>
              </el-form-item>
              <el-form-item label="标签">
                <dynamic-tag v-model="editForm.tags"></dynamic-tag>
              </el-form-item>
              <!--          <el-form-item label="来源">
                          <div class="mb10">
                            <el-select v-model="editForm.source" placeholder="来源" class="sel-source" @change="handleSourceChange">
                              <el-option :value="1" label="原创"></el-option>
                              <el-option :value="2" label="转载"></el-option>
                            </el-select>
                          </div>
                          <div class="mb10">
                            <el-input v-model="editForm.originalLink" placeholder="请输入原文链接" clearable
                                      v-if="editForm.source === 2" style="width: 500px"></el-input>
                          </div>
                        </el-form-item>
                        <el-form-item>
                          <el-checkbox v-model="editForm.isTop">置顶</el-checkbox>
                        </el-form-item>-->
              <el-form-item label="上传视频">
                <video-upload v-model="video" width="260px"></video-upload>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" :loading="loadingPublish" @click="handlePublish">发布</el-button>
                <el-button plain :loading="loadingSave" @click="handleSave">保存草稿</el-button>
                <el-button plain @click="handleGotoEditPage">继续新增</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { MATERIAL_SOURCE, MATERIAL_STATUS } from '@/utils/contants'
import { CmsVideoApi } from '@/api'
import moment from 'moment'

const defaultForm = () => {
  return {
    id: null,
    title: null,
    categories: [],
    tags: [],
    isTop: false,
    originalLink: null,
    source: MATERIAL_SOURCE.reprint,
    status: MATERIAL_STATUS.publish,
    videoCoverUrl: null,
    videoUrl: null,
    videoTime: null,
    publishTime: moment().add(1, 'h').format('YYYY-MM-DD HH:mm:ss'),
    isPush: false
  }
}

export default {
  name: 'VideoEdit',
  data () {
    return {
      searchForm: {
        type: 1
      },
      editForm: defaultForm(),
      rules: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          }
        ],
        categories: [
          {
            required: true,
            message: '请选择类型',
            trigger: 'blur'
          }
        ],
        publishTime: [
          {
            required: true,
            message: '请选择发布时间',
            trigger: 'blur'
          }
        ]
      },
      loadingPublish: false,
      loadingSave: false,
      loading: false,
      loadingUpload: false,
      video: null,
      pickerOptions: {
        shortcuts: [{
          text: '明天',
          onClick (picker) {
            picker.$emit('pick', moment().add(1, 'd').hour(7).minute(50).second(0).toDate())
          }
        }, {
          text: '后天',
          onClick (picker) {
            picker.$emit('pick', moment().add(2, 'd').hour(7).minute(50).second(0).toDate())
          }
        }],
        disabledDate (time) {
          return time.getTime() < moment().add(-1, 'd').toDate()
        }
      }
    }
  },
  methods: {
    initData () {
      const videoId = this.$route.query.id
      if (videoId) {
        this.loading = true
        CmsVideoApi.getVideo(videoId).then((result) => {
          this.loading = false
          this.editForm = Object.assign({}, result)
          this.video = {
            video: { fileUrl: this.editForm.videoUrl },
            cover: { fileUrl: this.editForm.videoCoverUrl }
          }
        }).catch(() => {
          this.loading = false
        })
      } else {
        this.video = null
        this.editForm = defaultForm()
      }
    },
    handleSourceChange (val) {
      if (val === MATERIAL_SOURCE.original) {
        this.editForm.originalLink = null
      }
    },
    valid () {
      if (!this.editForm.title || this.editForm.title.length === 0) {
        this.$message.error('请输入文章标题')
        return false
      }

      if (this.editForm.categories.length === 0) {
        this.$message.error('请选择分类')
        return false
      }
      if (this.video == null) {
        this.$message.error('请上传视频')
        return false
      }
      return true
    },
    async doSave () {
      this.editForm.videoUrl = this.video.video.fileUrl
      this.editForm.videoCoverUrl = this.video.cover.fileUrl
      this.editForm.videoTime = this.video.video.fileTime
      const data = await CmsVideoApi.saveVideo(this.editForm)
      Object.assign(this.editForm, data)
    },
    handlePublish () {
      if (!this.valid()) {
        return
      }
      this.editForm.status = MATERIAL_STATUS.publish
      this.loadingPublish = true
      this.doSave().then(() => {
        this.$message.success('发布成功')
        this.loadingPublish = false
      }).catch(() => {
        this.loadingPublish = false
      })
    },
    handleSave () {
      if (!this.valid()) {
        return
      }
      this.editForm.status = MATERIAL_STATUS.saved
      this.loadingEdit = true
      this.doSave().then(() => {
        this.$message.success('保存成功')
        this.loadingEdit = false
      }).catch(() => {
        this.loadingEdit = false
      })
    },
    handleGotoEditPage () {
      this.$router.replace({
        path: '/refresh',
        query: {
          id: '',
          t: Date.now()
        }
      })
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style lang="less">

#video-wrapper {
  margin-top: 10px;

  video {
    width: 280px;
  }
}

#cover-wrapper {
  margin-top: 10px;

  img {
    width: 280px;
  }

  .cover-label {
    margin-bottom: 13px;
  }
}
</style>
